<template>
  <Loader :value="promises.fetch" theme="default" class="gallery-page">
    <Blocks :blocks="config" ref="blocks" :custom-components="customComponents" />
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { animate, stagger } from 'motion'

import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/gallery'
import Masonry from '@/components/Custom/Masonry'

export default {
  computed: {
    ...mapState('Project', ['promises']),
    config() {
      return config.call(this)
    },
    customComponents() {
      return {
        Masonry,
      }
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },
  mounted() {
    this.promises.fetch.then(() => {
      this.$nextTick(() => {
        animate(
          '.gallery *',
          { opacity: 1, transform: 'none' },
          { duration: 1, delay: stagger(0.05) },
        )
      })
    })
  },
  beforeDestroy() {},
  components: {
    Blocks,
    Masonry,
  },
  metaInfo() {
    return {
      title: this.$tc('gallery', 1),
    }
  },
}
</script>

<style lang="scss">
.gallery-page {
  background-color: var(--light-grey);
}
</style>
